<template>
<b-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
    <template #button-content>
        <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" v-b-tooltip.hover="$t(currentLocale.name)" />
    </template>
    <b-dropdown-item v-for="localeObj in locales" :key="localeObj.locale" @click="handalChange(localeObj.locale)">
        <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
        <span class="ml-50">{{ $t(localeObj.name) }}</span>
    </b-dropdown-item>
</b-dropdown>
</template>

<script>
import {
    BDropdownItem,
    BImg
} from 'bootstrap-vue'

const initlocales = [
    {
        locale: 'pt',
        img: require('@/assets/images/flags/pt.png'),
        name: 'Portuguese Brazilian',
    },
    {
        locale: 'es',
        img: require('@/assets/images/flags/es.png'),
        name: 'Spanish',
    },
    {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
    },
    {
        locale: 'fr',
        img: require('@/assets/images/flags/fr.png'),
        name: 'French',
    },
    {
        locale: 'de',
        img: require('@/assets/images/flags/de.png'),
        name: 'German',
    },
    {
        locale: 'he',
        img: require('@/assets/images/flags/eb.png'),
        name: 'Hebrew',
    },
    {
        locale: 'ar',
        img: require('@/assets/images/flags/ar.png'),
        name: 'Arabia',
    },

]

export default {
    components: {
        BDropdownItem,
        BImg,
    },
    data() {
        return {
            locales: [...initlocales],
            locale: 'pt',
        }
    },
    // ! Need to move this computed property to comp function once we get to Vue 3
    computed: {
        currentLocale() {
            return this.locales.find(l => l.locale === this.$i18n.locale)
        },
    },
    methods: {
        handalChange(event) {
            localStorage.setItem('locale', event)
            this.$i18n.locale = event
        }

    }
}
</script>

<style>

</style>
